<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useProjectsStore } from "@/stores/db/projects";
import type { IUserForms } from "~/types";

const loggedIn = computed(() =>
  useAuth().data.value?.accessToken ? true : false
);

const drawer = ref<boolean>(true);
const forms = ref<IUserForms[]>([])
const { currentProject, userProjects, userFormsByProject } = storeToRefs(
  useProjectsStore()
);
const { guestFormsAll, findGuestFormByDomain, findAllGuestFormsByProject } = useGuest()

const { fetchUserFormsByProject } = useProjectsStore();

watchEffect(async () => {
  if (currentProject.value && userProjects.value.length > 0)
    if (typeof currentProject.value.id === "number") {


      if (loggedIn.value) {
        await fetchUserFormsByProject();
        forms.value = userFormsByProject.value as IUserForms[]
      }
      else forms.value = guestFormsAll.value as IUserForms[]


    } else {
      cl(
        "AppRightDrawer.watchEffect",
        `NOT CURRENT PROJECT?: ${JSON.stringify(currentProject.value)}`
      );
    }
});
function isExternalLink(link: string) {
  return link.startsWith('http')
}

await findGuestFormByDomain()
await findAllGuestFormsByProject()

// await fetchUserFormsByProject()

if (loggedIn.value)
  forms.value = userFormsByProject.value as IUserForms[]
else forms.value = guestFormsAll.value as IUserForms[]

cl("AppDrawerFormMenu");
// console.log(JSON.stringify(forms.value));

</script>

<template>
  <div>

    <v-card>
      <v-navigation-drawer v-model="drawer"
                           permanent>
        <v-list density="compact"
                nav>
          <v-list-item class="foo cursor-pointer"
                       v-for="item in forms"
                       nav
                       :append-icon="isExternalLink(item?.form_link) ? `mdi-open-in-new` : undefined"
                       :key="item?.form_id">
            <AppLink class="link  d-block w-100 text-decoration-none text-grey-darken-4"
                     :to="item?.form_link"> {{ item?.form_title }}</AppLink>

          </v-list-item>

        </v-list>
      </v-navigation-drawer>
    </v-card>

  </div>
</template>
<style scoped>
.foo:hover {
  background: #E0E0E0;
}
</style>
