import type { IUserForms, IVGuestForm } from "~/types"

export const useGuest = () => {
    const nuxtApp = useNuxtApp()
    const router = useRouter()
    const requestUrl = useRequestURL()

    const guestProjectId = ref()
    const guestFormsAll = ref<IUserForms[]>()

    const findGuestFormByDomain = async () => {
        // const config = useRuntimeConfig();
        const { data, error } = await useCustomFetch<IVGuestForm>(`guest-forms/domain/${requestUrl.hostname}`, {
            pick: ['id', 'domain', 'formId', 'projectId']
        });
        // cl('useGuest.findGuestFormByDomain', `result: ${JSON.stringify(data.value)}`)
        // cl('useGuest.findGuestFormByDomain', `error: ${JSON.stringify(error.value)}`)
        if (!error.value) {
            guestProjectId.value = data.value?.projectId
        }
        // cl('useGuest.findGuestFormByDomain', `projectId: ${data.value?.projectId}`)
        return data.value
        // const result = await $fetch(`${config.public.baseUrl}/guest-forms/domain/${requestUrl.hostname}`)
        // return result
    }
    const findAllGuestFormsByProject = async () => {
        // const config = useRuntimeConfig();
        // cl('useGuest.findAllGuestFormsByProjectks', `projectId: ${guestProjectId.value}`)
        if (!guestProjectId.value) return []
        if (guestProjectId.value === undefined) return []
        const { data, error } = await useCustomFetch<IUserForms[]>(`users/forms/projects/${guestProjectId.value}/all-guest-forms`, {
        });
        // cl('useGuest.findAllGuestFormsByProjectks', `result: ${JSON.stringify(data.value)}`)
        if (!error.value) {
            guestFormsAll.value = data.value as IUserForms[]
        }

    }
    return { guestProjectId, guestFormsAll, findGuestFormByDomain, findAllGuestFormsByProject }
}