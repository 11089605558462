<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAlertStore } from '@/stores/alert';


const { alert, } = storeToRefs(useAlertStore())



</script>

<template>
  <div>
    <v-alert
      v-if="alert"
      :type="alert?.type"
      :text="alert?.message"
      :title="alert?.title"
      :variant="alert?.variant"
      density="comfortable"
      closable
    />
  </div>
</template>